import * as SentryNode from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';

const isProduction = process.env.NODE_ENV === 'production';

export const setupSentry = () => {
	if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
		const integrations = [];
		if (
			process.env.NEXT_IS_SERVER === 'true' &&
			process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
		) {
			// For Node.js, rewrite Error.stack to use relative paths, so that source
			// maps starting with ~/_next map to files in Error.stack with path
			// app:///_next
			integrations.push(
				new RewriteFrames({
					iteratee: (frame) => {
						frame.filename = frame.filename.replace(
							process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
							'app:///'
						);
						frame.filename = frame.filename.replace('.next', '_next');
						return frame;
					},
				})
			);
		}

		SentryNode.init({
			enabled: process.env.NODE_ENV === 'production',
			integrations,
			dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
			release: process.env.NEXT_PUBLIC_COMMIT_SHA,
			environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
		});
	}
};

export default class Sentry {
	static async serverException(exception, flush = true) {
		SentryNode.captureException(exception);

		// Flushing before returning is necessary if deploying to Vercel, see
		// https://vercel.com/docs/platform/limits#streaming-responses
		if (flush) {
			await SentryNode.flush(2000);
		}
	}

	static clientException(exception) {
		SentryNode.captureException(exception);
	}

	static event(name, event) {
		if (!isProduction) return;

		SentryNode.captureEvent({ ...event, event_id: name, level: 'Info' });
	}
}
